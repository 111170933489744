<template>
  <div class="main">
    <div class="course content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <span>Dashboard</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> Course </a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="add">
            <a-button type="primary">
              <router-link to="/course/add"><PlusOutlined /> Add Course</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <a-row class="widgets" v-if="statistics">
        <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="widget">
            <div class="active_course">
              <a-row>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <PaperClipOutlined :style="{fontSize: '50px'}" />
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value">{{statistics.active_course}}</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="text">Active Course</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="widget">
            <div class="pending_course">
              <a-row>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <PaperClipOutlined :style="{fontSize: '50px'}" />
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value">{{statistics.pending_course}}</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="text">Pending Course</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="widget">
            <div class="free_course">
              <a-row>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <StarOutlined :style="{fontSize: '50px'}" />
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value">{{statistics.free_course}}</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="text">Free Course</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="widget">
            <div class="paid_course">
              <a-row>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <TagOutlined :style="{fontSize: '50px'}" />
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value">{{statistics.paid_course}}</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="text">Paid Course</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
      </a-row>
      <ListCourse :courseType="courseType" :courseId="courseId" @refreshCourseStatistics="refreshCourseStatistics" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, PlusOutlined, PaperClipOutlined, StarOutlined, TagOutlined } from '@ant-design/icons-vue'

import ListCourse from '@/components/agency/course/ListCourse.vue'

import courseService from '../../../services/course'

interface CourseStatistc {
  active_course: number;
  pending_course: number;
  paid_course: number;
  free_course: number;
}

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    ListCourse,
    PaperClipOutlined,
    StarOutlined,
    TagOutlined
  },
  setup () {
    const courseType = ref<string>('Parent')
    const courseId = ref<string>('')
    const statistics = ref<CourseStatistc>()
    const getCourseStatistics = async () => {
      const responce: any = await courseService.getStatistics()
      statistics.value = responce.data
    }
    const refreshCourseStatistics = async () => {
      getCourseStatistics()
    }
    onMounted(() => {
      getCourseStatistics()
    })
    return {
      courseType,
      courseId,
      statistics,
      getCourseStatistics,
      refreshCourseStatistics
    }
  }
})
</script>

<style lang="scss">
.course {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .add {
      float: right;
      .ant-btn {
        background-color: #38B6FF;
        border-color: #38B6FF;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
  .widgets {
    .widget {
      padding: 20px 10px;
      color: #ffffff;
      .active_course,
      .pending_course,
      .free_course,
      .paid_course {
        min-height: 125px;
        border-radius: 10px;
        padding: 15px;
        text-align: left;
        .value {
          font-family: "TT Norms Pro Medium";
          font-size: 40px;
          text-align: right;
          padding-right: 5px;
        }
        .text {
          font-weight: bold;
          margin-top: 5px;
        }
      }
      .active_course {
        background-image: linear-gradient(to right, #38B6FF, #38B6FF);
      }
      .pending_course {
        background-image: linear-gradient(to right, #f6596f, #f6596f);
      }
      .free_course {
        background-image: linear-gradient(to right, #11cdef, #11cdef);
      }
      .paid_course {
        background-image: linear-gradient(to right, #ffc788, #ffc788);
      }
    }
  }
}
</style>
